// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@charset 'utf-8';
 
@import 'settings';
@import 'foundation';
// @import 'motion-ui';
 
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
//@include foundation-badge;
@include foundation-breadcrumbs;
//@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-flex-video;
@include foundation-label;
//@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
 
// @include motion-ui-transitions;
// @include motion-ui-animations;


///////////////////////////
// CUSTOM STYLES BELOW HERE
///////////////////////////

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

.logobar {
	background-color:#444;	
	border-bottom:1px solid #fff;
}

.headerLogo {
	display:flex;
	align-items:center;
	height:5em;
	background: url("../graphics/header.jpg") no-repeat center;
}

.logoText {
	font-size:200%;
	color:#FFF;
	text-align:left;
	vertical-align:center;
	text-transform: uppercase;
	font-family: 'Yanone Kaffeesatz', sans-serif;
	letter-spacing: .05em;
	text-shadow:2px 2px #000;

	span {
	color:#FAE658;
	font-weight:bold;		
	}
}

.navbar {
	background-color:#000;
	color:#FFF;
}

.navbar-siderails {
	border-left:1px solid #fff;
	border-right:1px solid #fff;
}

.top-level-menu .menu  {
	box-shadow: 3px 3px 5px rgba(0,0,0,.25);
}

.menu > li > a {
	color:white;
	font-size:0.85rem;
	padding:1.1rem;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.menu > div > a {
	color:white;
	font-size:0.85rem;
	padding:.75rem;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.top-level-menu > li:hover > a {
	background:#40586b;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.top-level-menu > li > .menu > li:hover > a, .top-level-menu > li > .menu > li > .menu > li:hover > a {
	background:rgba(255,255,255,.2);
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.top-level-menu > li > .menu li a:hover {
	background:rgba(255,255,255,.2);
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.menu > li > a > i {
	margin-left:1em;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.menu > li:hover > a > i {
	transform: rotate(90deg);
}

.navChildDropdown {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 350px;
}

.navChildDropdown a {
	display: inline-block;
	color: #fff;
}

.main-content-area {
	background-color: $white;
	box-shadow: 0 3px 5px 2px #ddd;
}

article a:hover {
	text-decoration:underline;
}

.Cataloged-row 			{ background-color:#dce5e9; transition: all 0.1s;}
.Cataloged-row:hover 	{ background-color:#3c7d99; color: $white; }
.Confirmed-row 			{ background-color:#e0e9dc; transition: all 0.1s;}
.Confirmed-row:hover 	{ background-color:#56a54f; color: $white; }
.Unconfirmed-row 		{ background-color:#f5f3e3; transition: all 0.1s;}
.Unconfirmed-row:hover 	{ background-color:#d5a216; color: $white; }
.Unknown-row 			{ background-color:#efd2d2; transition: all 0.1s;}
.Unknown-row:hover 		{ background-color:#bd1e2d; color: $white; }
.Disqualified-row 		{ background-color:#cbcbcb; transition: all 0.1s;}
.Disqualified-row:hover { background-color:#6b6b6b; color: $white; }
.Posted-row 			{ background-color:#d8cfc4; transition: all 0.1s;}
.Posted-row:hover 		{ background-color:#825E42; color: $white; }
.Inundated-row 			{ background-color:#e3dce9; transition: all 0.1s;}
.Inundated-row:hover 	{ background-color:#693494; color: $white; }

.Cataloged-row:hover a, .Confirmed-row:hover a, .Unconfirmed-row:hover a, .Unknown-row:hover a, .Disqualified-row:hover a, .Inundated-row:hover a, .Posted-row:hover a	{ color: $white; font-weight: bold; }

.tableWrapper {
	display:table;
	width:100%;
	border-collapse:separate; 
    border-spacing:1px;
    text-align: left;

	.tableRow-Header, .tableRow {
		display: table-row;
		width:100%;
	}

	.tableRow-Header {
	    background-color:#40586b;
		color: #FFF;
		font-weight:bold;

		a > i {
			padding-left: .5em;
			color: white;
		}
	}

	.tableCell {
		display: table-cell;
		padding:.5em;
	}

	.fullWidthCell {
		width:100%;
	}
}

.tableIconColumn {
	width: 3.4em;
}

.table-accessIconCell {
	max-width:3.75em;
}

.nwscomparetable {
	border-collapse:separate;
	border-spacing:1px;
	text-align:left;
	font-size:90%;
	background-color:$medium-gray;

	th {
		background-color:$dark-gray;
		color:$white;
	}

	td {
		background-color:$light-gray;		
	}

	td.empty {
		background-color:$white;
	}
}

.letterSort {
	font-size: 85%;
	padding: .25em .5em .25em .5em;
	font-weight: bold;
	border-radius: 3px;
	text-decoration: none;
	-webkit-transition: all 0.1s; -moz-transition: all 0.1s; transition: all 0.1s;
}

.letterSort:hover {
	background-color:#40586b;
	color: #FFF;
	text-decoration:none;
}

.sortColumn {
	background: rgba(255,255,255,.3); 
}

.updateTimestamp {
	border-bottom: 5px solid gray;
}

hr {
	border:1px solid #EEE;
	margin-top:-1em;
}

.modalOpenRight {
	float: right;
	margin-left: 8px;
	margin-top:5px;
}

a.button > i {
	padding-right:1em;
}

a.button:hover {
	text-decoration:none;
}

.mapbuttons {
	display:inline-block;
}

.indent {
	margin-left:2em;
}

.searchframe {
	padding:1em;
}

#searchbox {
	width:85%;
}

#searchbutton {
	display:block;
	position:absolute;
	top:1.75em;
	left:10.5em;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Sidebar Specific ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
aside {
	width:100%;
	height:auto;
	float:right;
	margin-left:3em;
	margin-bottom:3em;
}

.waterfall-info-sidebar table {
	background-color:#EAEAEA;
	width:100%;
	border:10px solid #40586b;
	border-collapse:collapse;
	margin-bottom:0px;

	th {
		background-color:#40586b;
		color:#FFF;
		font-family: 'Sintony', sans-serif;
		font-size:110%;
		text-transform:uppercase;
		padding:.5em;
		text-align:left;

		a {
			color:$white;
		}
		a:hover {
			color:$light-gray;
		}
	}

	th+th {
		color:#FAE658;
	}

	tr:nth-child(even) {
		background-color:#F4F4F4;
	}

	.tableIcon {
		padding-right:.5em;
	}
}


.waterfall-info-sidebar table td {
	font-size:80%;
	padding:.5em;
}
.waterfall-info-sidebar table td:first-child {
	padding-left:1em;
	width:45%;
	font-weight: bold;
}
.waterfall-info-sidebar table td:second-child {
	padding-right:1em;
	width:55%;
}

.waterfall-info-sidebar table.Cataloged { border-color: #3c7d99!important; }
.waterfall-info-sidebar table.Cataloged th{	background-color:#3c7d99; }

.waterfall-info-sidebar table.Confirmed { border-color: #56a54f!important; }
.waterfall-info-sidebar table.Confirmed th {	background-color:#56a54f; }

.waterfall-info-sidebar table.Unconfirmed { border-color: #d5a216!important; }
.waterfall-info-sidebar table.Unconfirmed th {	background-color:#d5a216; }

.waterfall-info-sidebar table.Unknown, .waterfall-info-sidebar table.Rumored { border-color: #bd1e2d!important; }
.waterfall-info-sidebar table.Unknown th, .waterfall-info-sidebar table.Rumored th {	background-color:#bd1e2d; }

.waterfall-info-sidebar table.Disqualified { border-color: #6b6b6b!important; }
.waterfall-info-sidebar table.Disqualified th {	background-color:#6b6b6b; }

.waterfall-info-sidebar table.Posted { border-color: #825E42!important; }
.waterfall-info-sidebar table.Posted th {	background-color:#825E42; }

.waterfall-info-sidebar table.Inundated { border-color: #693494!important; }
.waterfall-info-sidebar table.Inundated th {	background-color:#693494; }

#statusIcon {
	vertical-align:middle;
	margin-left: -.5em;
	height: 2em;
}

.tableIcon {
	height: 2em;
}

.fullIcon {
	height: 3em;
	max-width:none;
}

#iconCell {
	margin: .5em 0 1em 0;
	padding: .25em 0 .25em 0;
	text-align: center;

	img {
		display: inline-block;
		margin:auto;
	}
}

.sortColumn {
	background: rgba(255,255,255,.3); 
}

.keytable {
	border:1px solid #000;
	cursor: default!important;
	
	tr {
		margin: 1px;
		
		td {
			padding:1em;
			font-weight: normal;
		}
	}
}


#pictureCarousel, #pictureCarousel2 {
	min-height:190px;
	margin-top: auto;
	text-align: center;
	margin-bottom: auto;

}

#pictureCarousel, #pictureCarousel2 {
	
	i:first-of-type {      /*for Prev button */
		position: absolute;
		left:0;
	}

	i:last-of-type {      /*for Next button */
		position: absolute;
		right:0;	
	}
}

.carouselImg {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	border:6px solid white;
	box-shadow: 2px 2px 5px 2px #999;
}

#pictureCarousel, #pictureCarousel2 {
	
	.noPics {
		text-align: center;
		font-size: 120%;
		font-weight: bold;
		margin: 1em;
	}
}

#pictureCarousel i, #pictureCarousel2 i {
  position: absolute;
  top: 40%;
  z-index: 999;
  display: block;
  padding: 4% 3%;
  width: auto;
  height: auto;
  color: #2a2a2a;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  opacity: 0.8;
  cursor: pointer;
}

.slick-disabled {
	color: #dfdfdf!important;
	cursor: default!important;
}

#pictureCarousel a, #pictureCarousel2 a, .top100Container div, .close-button {
	outline: 0;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ BODY SUB-AREAS ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.nameInfo {
	padding: 5px;
	background-color: #fafafa;
	border:1px solid #ececec;
	border-radius: 2px;
	width: 61%
}
.nameInfo span {
	font-weight: bold;
}

.nameAltList {
	font-size: 90%;
	margin-top: -.75em;
}

#nearbyFallsList > table {
	border-collapse:separate; 
    border-spacing:1px;
    text-align: left;
    padding-bottom: .75em;
}

#nearbyFallsList > table td {
	padding: .25em 2.5em 0 0;
}

h1 {
	color:#40586b;
	font-weight:bold;
}

h3.subheader {
	font-weight:bold;
	color:#999;
	margin-top:-.2em;
}

h4 {
	border-bottom: 3px solid #EFEFEF;
	margin: 1.8em 0 1em 0;
	color:#666;
}

h4 i {
	padding-right: .75em;
}

h4 span { 
	position: relative;
	top:.6em;
    background:#fff; 
    padding:0 .625em; 
}

.nameInfo {
	padding: 5px;
	background-color: #fafafa;
	border:1px solid #ececec;
	border-radius: 2px;
	width:100%;
}

.nameInfo span {
	font-weight: bold;
}

.nameAltList {
	font-size: 90%;
	margin-top: -.75em;
}

.reveal {
	outline:0;
	box-shadow: 0 3px 5px 2px rgba(0,0,0,.25);
	top:0!important;
}

.closeX {
	float:right;
	margin-top:-1.5em;
	margin-right:-1em;
	font-size:200%;
	color:$medium-gray;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.callout > i {
	font-size: 250%;
	float:left;
	padding-right: .5em;
}

.primary > i { color: map-get($foundation-palette, primary); }
.alert > i { color: map-get($foundation-palette, alert); }
.warning > i { color: map-get($foundation-palette, warning); }
.success > i { color: map-get($foundation-palette, success); }

.adcell {
	padding-top: 2em;
	padding-bottom:1em;

	p {
		text-align: center;
		font-size: 80%;
	}
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Footer Specific ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.footerContainer {
	max-width: $global-width;
	margin-left: auto;
  	margin-right: auto;
	display:flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	padding: 2em;

	div > h3 {
		color:$medium-gray;
	}
}

.footerContentSmall {
	flex: 1 auto;
	padding-left:2em;

	ul > li {
		list-style: none;
		margin-left:-1em;

		i {
			font-size: 200%;
			padding-top:.5em;
		}
	}
}

.footerContentLarge {
	flex: 2 auto;
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Map Box Classes ~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.mapbox, .mapboxlg {
	width: auto;
	background-color: #efefef;
	display:flex;
}

.mapbox {
	margin-top: .5em;
	min-height: 400px;
}

.mapboxlg {
	height: 650px;
}

.mapOptions {
	display: flex;
	justify-content:flex-start;
	//position: relative;
	background-color: $black;
	//cursor: pointer;
	//margin-top: 1em;

	.button {
		background: $black;
		margin:0!important;
	}

	.button:hover {
		background-color: $nwsBlue;
	}
}

// .mapOptions > li {
// 	display: inline-block;
// 	padding:1em;
// 	margin: 0;
// 	color: white;
// 	list-style-type: none;
// 	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
// }

// .mapOptions > li > a {
// 	color: white;
// 	text-decoration: none;
// }

.mapOptions i {
	padding-right: 1em;
}

// .mapOptions li:hover {
// 	background-color: #40586b;
// }

// .zoomToMenu {
// 	position: absolute;
//     display: block;
//     width: auto;
//     top: 52px;
//     z-index: 999;
//     background-color: white;
//     padding: 1em;
//     opacity: 0;
//     visibility: hidden;
//     overflow: hidden;
// }

// .zoomToMenu {
//     left: 0;
// }

// .displayControls {
//     left: 158px;
// }

.displayControls {
	padding-top:0;
    // width: 13px;
    // height: 13px;
    // padding: 0;
    // margin:0;
    //vertical-align: bottom;
    //position: relative;
    //top: -1px;
    //overflow: hidden;
}

.displayControls label:hover {
	cursor: pointer;
}

.displayControls label img {
	margin: 0 .5em 0 .5em;
}

#regular-checkbox {
	-webkit-appearance: none;
	background-color: #fafafa;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
	top:1.5em;
}

#regular-checkbox:active, #regular-checkbox:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

#regular-checkbox:checked {
	background-color: #e9ecee;
	border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #99a1a7;
}

#regular-checkbox:checked:after {
	content: '\2714';
	font-size: 14px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: #99a1a7;
}

.displayControls label img {
	height: 30px;
}

.mapOptions li:hover .zoomToMenu, .mapOptions li:hover .displayControls {
	opacity: 1;
    border-top: .35em solid #40586b;
    color: #000;
    visibility: visible;
    overflow: visible;
    box-shadow: 3px 3px 5px rgba(0,0,0,.25);
}

.ddMenuColumn {
	flex:1;
	padding-right:1em;
}

#zoomToMenu--Button {
	display:inline-flex;
}

// .ddMenuColumn {
// //    float:left;
// }

.ddMenuColumn > h3 {
	//margin: 1em 0 1em 0;
    //line-height: 1.25em;
    //font-weight: bold;
    font-size: 110%;
    //text-transform: uppercase;
}

.ddMenuColumn ul {
	list-style-type: none;
	padding: 0;
}

.ddMenuColumn ul li {
	margin-left:-1em;
// 	margin-right:1em;
// 	border-radius:3px;
// 	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

// .ddMenuColumn ul li > a {
// 	display: block;
//     padding: .25em 1em .25em 1em;
//     font-weight: bold;
//     font-size: 80%;
//     text-decoration: none;
// }

// .ddMenuColumn ul li:hover a {
// 	color:#fff;
// }

#loaderIcon {
	position:relative;
    top: -350px;
    left:48%;
    z-index: 10000;
    color: white;
    text-shadow: 0px 0px 6px #000000;
    visibility: hidden;
}

//deprecated
.tooltip { 
	display: block;
	position:absolute;
	margin-top:-20px;
	margin-left: 9px;
	width: auto;
	height:29px;
	line-height:20px;
	white-space: nowrap;
	padding: 5px 10px 5px 10px;
	border: none;
	font-family:Source Sans Pro, sans-serif;
	font-size: 120%;
	background-color: #393939;
	color: #fff;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	}

//deprecated
.tooltip:before {
	border:none;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Pagination ~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.pagination {
	list-style: none;
	text-align: center;
	padding-top:2em;
	padding-left:0;
}

.pagination li {
	display: inline-block;
	font-size: 85%;
}

.pagination li a {
	background-color: #efefef;
}

.pagination li a, ul.pagination li span {
	padding: .5em;
	margin: .5em;
	color: #40586b;
	border-radius: 3px;
	text-decoration: none;
	-webkit-transition: all 0.1s; -moz-transition: all 0.1s; transition: all 0.1s;
}

.pagination li a:hover {
	background-color: #40586b;
	color: white;
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Off-canvas ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.off-canvas > ul li {
	border-bottom: 1px solid rgba(255,255,255,.2);
}

.off-canvas > ul li > a:hover {
	background:$nwsBlue;
}

.mobile-nav {
	width:100%;
	display:flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items:center;
	justify-content:center;
}

.mobileNav-Button {
	flex-grow:1;
	display:inline-block;
	font-size:150%;
	padding: .5em;

	a > i {
		color:$white;
	}
}

.mobileNav-Searchfield {
	flex-grow:5;
	display:inline-block;
	width:80%;
	padding: 0 .25em 0 .25em;

	input {
		margin:0;
	}
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Home Page Stuff ~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.hpContentFullWidth {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.hpThirdWidthCell {
	display:flex;
	align-items:stretch;

	div {
		margin:auto;
		height:22em;
		width:100%;
	}

	a .primary .logoText {
		font-weight:bold;
		color: $nwsBlue;
		text-shadow: none;
	}

	a .primary {
		-webkit-transition: -webkit-transform 0.35s, color 0.35s;
		transition: all 0.35s, color 0.35s;
	}

	a .primary:hover {
		background: rgba($nwsBlue,.2);
	}
}

.hpAboutCell {
	display: flex;
	flex-direction:column;
	justify-content:space-around;
	align-items:baseline;
}

.hpAboutCell > h1 {
	color: white;
	width: 100%;
	margin: auto;
	padding:1em;
	text-shadow: 4px 4px 15px #000;
}

.hpAboutCell > p {
	color: white;
	width: 100%;
	margin: auto;
	background: rgba(0,0,0,.25);
}

.stateButtonBar {
	background: -webkit-linear-gradient(top, rgba(68,68,68,0) 0%, rgba(20,20,20,0.8) 85%);
	background: linear-gradient(to bottom, rgba(68,68,68,0) 0%, rgba(20,20,20,0.8) 85%);
	color: white;
	padding: 2em;

	div {
		display:flex;
		align-items: center;
		align-content:center;
		flex-direction:column;
	}
}

.buttonExtraRightMargin {
	margin-right:1.5em;
}

.hpSearchCell {
	display: flex;
	justify-content:space-around;
	align-items:center;
	background-color: $black;
	color: $white;
	width: 100%;

}

.hpSearchCell input {
	margin: 2em 0 2em 0;
	padding:1em;
	font-size: 140%;
	line-height: 140%;
	box-sizing: border-box;
}

.hpContentFullWidth form {
	width:100%;
}

.hpSearchCell button i {
	font-size: 250%;
	margin-left: -1em;
}

.hpSearchCell input {
	display: inline-block;
}

.featuredWaterfall {
	position: relative;
	overflow: hidden;

	a {
		display: flex;
		height:22em;
		flex-direction: column;
	}
}

/* hover effects */
.featuredWaterfall {
	a {
		background: rgba($nwsBlue,.55);
	}

	h2, p {
		opacity: 1;
		width: 100%;
		background: rgba(255,255,255,.35);
		text-shadow: 4px 4px 15px #000;
		text-align: center;
	}

	h2 {
		padding:.75em;
	}

	p {
		font-size: 125%;
		padding:.5em;
	}

	h2, p, i, a {
		color:$white;
		-webkit-transition: -webkit-transform 0.35s, color 0.35s;
		transition: all 0.35s, color 0.35s;
	}
}

.featuredWaterfall i {
	width:100%;
	font-size:500%;
	color:$white;
	padding-top:.5em;
	padding-left:.25em;
	flex-grow: 2;
	text-align: center;
	opacity: 0;
}

.featuredWaterfall:hover {
	h2, p {
		opacity: 0;
		background: none;
	}

	a {
		background: rgba($nwsBlue,0); 
	}
}

.featuredWaterfall:hover i {
	opacity: .5;
}

.hpBackgroundImg {
	background-position: center;
	background-repeat: no-repeat;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Google Maps Infowindows ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(4),
#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(1) > div,
#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(2) > div {
	background: $dark-gray!important;
}

#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(3)::before {
	font-family: FontAwesome;
	content: '\f00d';
	color: $white;
	padding-left:5px;
}

#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(3) > img {
	display: none;
}

.gm-style-iw {
	top: 11px !important;
	background: $dark-gray;
	color:$white;
	font-size: 15px!important;

	a {
		color:$white;
		text-decoration: none;
	}
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Media Queries ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

@media only print {
   body { min-width:$global-width; }

	div.sidebar-for-print {
		width:300px;
		height:auto;
		float:right;
		margin-left:3em;
		margin-bottom:3em;
		background: $white;
	}

	div.large-8 {
		width:1100px;
	}
	
	h4 {
		border-bottom: none;
	}

	.nameInfo {
		border:none;
	}

	img { 
    	max-width: none !important; 
	}

	.print-sidebar-image {
		padding-top:10px;
		padding-bottom:10px;
	}
}


@media only screen and (max-device-width : 767px) {

	.main-content-area {
		padding: 1em .5em 1em .5em;
	}

	.logoText {
		text-align:center;
		font-size: 150%;
	}

	.footerContainer {
		font-size:80%;
	}

	.hpAboutCell > h1 {
	font-size: 250%;
	}

	.hpAboutCell > p {
		font-size: 90%;
		padding:.5em 2em .5em 2em;
	}

	.hpSearchCell input {
		width: 65%;
	}

	a .primary .logoText {
		font-size:275%;
	}
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

	.main-content-area {
		padding:1em;
	}

	.hpAboutCell > h1 {
	font-size: 350%;
	}

	.hpAboutCell > p {
		font-size: 110%;
		padding:2em 5em 2em 5em;
	}

	.hpSearchCell input {
		width: 75%;
	}	

	a .primary .logoText {
		font-size:325%;
	}
}



@media only screen and (min-device-width : 1025px) {

	.main-content-area {
		padding:2em;
	}

	.hpAboutCell > h1 {
		font-size: 400%;
	}

	.hpAboutCell > p {
		font-size: 150%;
		padding:2em 5em 2em 5em;
	}

	.hpSearchCell input {
		width: 85%;
	}

	a .primary .logoText {
		font-size:325%;
	}
}

@media only screen and (min-width : 1200px) {

	a .primary .logoText {
		font-size:400%;
	}
}	













///////////////////////////////
// DEPRECATED
///////////////////////////////

/*.nwsdatatable {
	border-collapse:separate; 
    border-spacing:1px;
    text-align: left;

    tr { 

    	th {
    	background-color:#40586b;
		color: #FFF;
		}

		a > i {
			padding-left: .5em;
			color: white;			
		}

		td:first-child {
			width: 3.4em;
		}
    }
}

.footerContent {

	margin-top:2em;

	h3 {
		color:$medium-gray;
	}

	ul > li {
		list-style: none;
		margin-left:-1em;
	}

}*/